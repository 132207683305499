@import "./variables/colors.scss";
@import "./variables/dimen.scss";

@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,300;0,400;0,600;0,700;0,900;1,300;1,400;1,600;1,700;1,900&display=swap');

html,
body,
#root,
.app,
.content {

  font-family: 'Source Sans Pro';

}


.sidebar {
  a {
    display: block;

    &:hover {
      background-color: rgba($color: #ffffff, $alpha: .1);
    }
  }
}


.context-menu {
  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 0.9em;
    color: $color-dark;
  }
}

img {
  &.fluid {

    max-width: 100%;
    height: auto;
    padding: 0;
    margin: 0;

  }

  &.kaizen-dark {
    max-width: 120px;
  }

  &.sidebar-logo {
    max-width: 140px;
  }
}

.standard-cell {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}